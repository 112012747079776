import { Component } from "react"
import { MenuItems } from "./menuItems"
import React from "react"
import { FaBars } from "react-icons/fa"

import { Nav, Logo, NavMenu, BurgerMenu, Drawer } from "./navElements"
import { useEffect, useState } from "react"

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false)

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset

      if (scrollTop > 0) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <Nav isScrolled={isScrolled}>
      <Logo href={"/"}>Naples</Logo>
      <NavMenu isScrolled={isScrolled}>
        {MenuItems.map((item, index) => {
          return (
            <li key={index}>
              <a href={item.url}>{item.title}</a>
            </li>
          );
        })}
      </NavMenu>
      <BurgerMenu
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        isOpen={isDrawerOpen}
      >
        <span />
        <span />
        <span />
      </BurgerMenu>
      <Drawer isOpen={isDrawerOpen}>
        {MenuItems.map((item, index) => (
          <a href={item.url} key={index}>
            {item.title}
          </a>
        ))}
      </Drawer>
    </Nav>
  )
}

export default Navbar
