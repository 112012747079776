import styled from "styled-components"

interface NavProps {
  isScrolled: boolean
}

interface BurgerMenuProps {
  isOpen: boolean
  onClick: () => void
}

interface DrawerProps {
  isOpen: boolean
}

export const Nav = styled.nav<NavProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-shadow: ${(props) =>
    !props.isScrolled ? "none" : "0 5px 15px 0 rgba(0, 5, 5, .10)"};
  background-color: ${(props) =>
    !props.isScrolled ? "transparent" : "rgba(38, 38, 38, .6)"};
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
  transition: all 0.2s linear;
`

export const Logo = styled.a`
  color: ${(props) => props.theme.colors.primary};
  font-size: 3rem;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  margin-left: 40px;

  &:hover {
    color: ${(props) => props.theme.colors.pink};
  }
`

export const NavMenu = styled.ul<NavProps>`
  list-style-type: none;

  & li {
    display: inline-block;
  }

  & a {
    display: block;
    padding: 10px 20px;
    margin: 30px 0;
    text-decoration: none;
    cursor: pointer;
    font-size: 1rem;
    color: ${(props) => (!props.isScrolled ? "#fff" : "#fff")};
    transition: all 0.2s linear;
    letter-spacing: 1px;

    &:hover {
      color: ${(props) => props.theme.colors.pink};
    }
  }

  @media (max-width: 1024px) {
    & a {
      display: none;
    }
  }
`

export const BurgerMenu = styled.div<BurgerMenuProps>`
  display: none;
  width: 50px;
  height: 32px;
  cursor: pointer;

  & > span {
    display: block;
    width: 100%;
    height: 2px;
    position: relative;
    background-color: #fff;
    transition: all 0.3s linear;

    &:first-of-type {
      transform: ${(props) =>
        props.isOpen ? "translateY(14px) rotate(45deg)" : "rotate(0deg)"};
    }

    &:nth-child(2) {
      top: 13px;
      opacity: ${(props) => (props.isOpen ? 0 : 1)};
    }

    &:last-of-type {
      top: 26px;
      transform: ${(props) =>
        props.isOpen ? "translateY(-14px) rotate(-45deg)" : "rotate(0deg)"};
    }
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`

export const SignButton = styled.li`
  display: inline-block;
  border-radius: 0.3rem;
  font-size: auto;
  font-weight: 550;
  border: none;
  cursor: pointer;

  & > a:hover {
    background-color: #fbac03;
    transition: background-color 0.2s ease-in-out;
    color: #fff;
  }
`

export const Img = styled.img`
  padding: 10px;
  display: flex;
  align-items: center;
  width: 8;
  height: 70px;
`

export const Drawer = styled.div<DrawerProps>`
  height: calc(100vh - 100px);
  background-color: #aaa;
  width: 100vw;
  position: absolute;
  top: ${(props) => (props.isOpen ? "100px" : "-100vh")};
  left: 0;
  transition: all 0.3s linear;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > a {
    color: #fff;
    font-size: 1.5rem;
    text-decoration: none;
    margin-bottom: 1rem;
  }
`
