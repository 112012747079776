import React from "react";
import {
  Header,
  Box,
  TextBox,
  About,
  Container,
  Photos,
  AboutContent,
  Button,
  Card,
  Detail,
  ParallaxContainer,
  Background,
  ArrowContainer,
  ArrowIcon,
  Information,
} from "./mainElements";

const ParallaxEffect = ({ children }: { children: React.ReactNode }) => (
  <ParallaxContainer>
    <Background />
    {children}
  </ParallaxContainer>
);
const ScrollDownArrow: React.FC = () => {
  const handleClick = () => {
    window.scrollBy({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  return (
    <ArrowContainer onClick={handleClick}>
      <ArrowIcon />
    </ArrowContainer>
  );
};

const Home: React.FC = () => {
  return (
    <>
      <Header>
        <Box>
          <TextBox>
            <h1>Naples 247 Erie Dr </h1>
            <p>
              Perfect for those in search of a temporary haven in the
              prestigious Collier County this home offers comfort, style and
              excellent amenities. Whether you're looking for a place for a
              brief business trip, a short getaway or a temporary stay, 247 Erie
              Dr provides the everything you need.
            </p>
          </TextBox>
        </Box>
        <ScrollDownArrow></ScrollDownArrow>
      </Header>
      <About id="about">
        <h1>Naples Erie Dr</h1>
        <p></p>
        <Container>
          <Photos>
            <div />
          </Photos>
          <AboutContent>
            <p>
              Welcome to your dream home away from home in the highly
              sought-after Willoughby Acres community. This single-family pool
              home sits on an end lot on a dead end street, where you can rest
              assured that no traffic will ever be a concern for your youngsters
              or furry friends. Offering a stunning, fully remodeled, interior
              and an array of features that will make you feel like you're on
              vacation every day. And, not only aesthetically pleasing with its
              inviting and elegant finishes put into its recent renovation, but
              the peace of mind it brings by having been upgraded to impact
              resistant windows and a brand new HVAC is a big plus! With the
              property being offered "turnkey furnished," you can simply bring
              your personal belongings and start enjoying the Naples lifestyle
              right away. The true jewel of this property is the inviting
              fenced-in pool with a brand new electric heater and backyard
              space, the ultimate gathering spot! The perfect retreat with
              complete privacy and the comfort and convenience for your Naples
              vacation! Book weekly or monthly for 2024 season!
            </p>
            <Button>
              <a href="#contact"> Request to apply</a>
            </Button>
          </AboutContent>
        </Container>
      </About>
      <ParallaxEffect>
        <div></div>
      </ParallaxEffect>
      <h1
        style={{
          color: "#d55a63",
          fontSize: "2.5rem",
          textAlign: "center",
          margin: "50px",
        }}
      >
        Details
      </h1>
      <Card id="details">
        <Detail>
          <span>Swiming pool</span>
          <span>1 sp</span>
        </Detail>
        <Detail>
          <span>Bedrooms</span>
          <span>2 bd</span>
        </Detail>
        <Detail>
          <span>Bathrooms</span>
          <span>2 ba</span>
        </Detail>
        <Detail>
          <span>Square Feet</span>
          <span>1,261 sq ft</span>
        </Detail>
      </Card>
      <Information id="features">
        <h1>House Features</h1>
        <p>
          Discover the unparalleled comfort and modern amenities that make
          Naples Erie Dr your dream home destination.
        </p>
        <div>
          <ul>
            <li>Cable</li>
            <li>Dishwasher</li>
            <li>Microwave</li>
            <li>Refrigerator</li>
          </ul>
          <ul>
            <li>Ice Maker</li>
            <li>Patio</li>
            <li>Fenced Lot</li>
            <li>Disposal</li>
          </ul>
        </div>
      </Information>
    </>
  );
};

export default Home;
