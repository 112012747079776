export const MenuItems = [
    {
        title : "HOME",
        url : "/",
        cName : "nav-links"
    },
    {
        title : "ABOUT",
        url : "#about",
        cName : "nav-links"
    },
    {
        title : "CONTACT",
        url : "#contact",
        cName : "nav-links"
    },
    {
        title : "DETAILS",
        url : "#details",
        cName : "nav-links" 
    },
    {
        title : "FEATURES",
        url : "#features",
        cName : "nav-links" 
    },
    
]