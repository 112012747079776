import Nav from "./components/nav"
import Main from "./views/main"
import { theme } from "./assets/theme"
import { ThemeProvider } from 'styled-components'
import Gallery from "./components/gallery/gallery"
import Contact from "./views/contact"
import Footer from "./components/footer"
function App() {
  return (
    <> 
    <ThemeProvider theme={theme}>
      <Nav />
      <Main />
      <Gallery />
      <Contact />
      <Footer />
    </ThemeProvider>
    </>
  )
}

export default App;
