import React, { useState } from 'react'
import styled from 'styled-components'
import GalleryItem from './galleryItem'
import Popup from './popup'
import img1 from './../../assets/images/outside3.jpg'
import img2 from './../../assets/images/outside2.jpg'
import img3 from './../../assets/images/diningRoom.jpg'
import img4 from './../../assets/images/diningRoom1.jpg'
import img5 from './../../assets/images/livingRoom.jpg'
import img6 from './../../assets/images/livingRoom1.jpg'
import img7 from './../../assets/images/livingRoom2.jpg'
import img8 from './../../assets/images/livingRoom3.jpg'
import img9 from './../../assets/images/livingRoom4.jpg'
import img10 from './../../assets/images/kitchen.jpg'
import img11 from './../../assets/images/kitchen1.jpg'
import img12 from './../../assets/images/kitchen2.jpg'
import img13 from './../../assets/images/kitchen3.jpg'
import img14 from './../../assets/images/kitchen4.jpg'
import img15 from './../../assets/images/primarybathroom.jpg'
import img16 from './../../assets/images/primarybathroom1.jpg'
import img17 from './../../assets/images/primarybedroom.jpg'
import img18 from './../../assets/images/primarybedroom1.jpg'
import img19 from './../../assets/images/primarybedroom2.jpg'
import img20 from './../../assets/images/primarybedroom3.jpg'
import img21 from './../../assets/images/primarybedroom4.jpg'
import img22 from './../../assets/images/washmachine.jpg'
import img23 from './../../assets/images/guestbedroom.jpg'
import img24 from './../../assets/images/guestbedroom1.jpg'
import img25 from './../../assets/images/bathroom.jpg'
import img26 from './../../assets/images/pool.jpg'
import img27 from './../../assets/images/pool1.jpg'
import img28 from './../../assets/images/pool2.jpg'
import img29 from './../../assets/images/hool.jpg'

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
  padding: 16px;
  margin: 30px;
`

const Gallery: React.FC = () => {
  const [selectedImg, setSelectedImg] = useState<string | null>(null)

  const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img29,
    img27,
    img28,
  ]

  return (
    <>
     <h1 id="gallery" style={{ color: '#d55a63', fontSize: '2.5rem', textAlign: 'center', margin: '50px' }}>Gallery</h1>
      <GalleryGrid >
        {images.map((img, index) => (
          <GalleryItem key={index} src={img} onClick={() => setSelectedImg(img)} />
        ))}
      </GalleryGrid>
      {selectedImg && <Popup src={selectedImg} onClose={() => setSelectedImg(null)} />}
    </>
  )
}

export default Gallery
