import {styled} from "styled-components"
import outside from "./../../assets/images/outside3.jpg"
import livingroom from "./../../assets/images/livingRoom.jpg"
import room3 from "./../../assets/images/livingRoom3.jpg"
import { BsChevronDown } from "react-icons/bs";

const icon = `
    margin-right: 1rem;
    font-size: 3rem;

    & > path {
        fill: #e3e3e3
`

export const ArrowIcon = styled(BsChevronDown)`
      ${icon}
`

export const ArrowContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  animation: bounce 2s infinite;

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateX(-50%) translateY(0);
    }
    40% {
      transform: translateX(-50%) translateY(-20px);
    }
    60% {
      transform: translateX(-50%) translateY(-10px);
    }
  }
`
export  const ParallaxContainer = styled.div`
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 1px;
`
export const Background = styled.div`
  background-image: url(${room3});
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
`

export const Header = styled.div`
min-height: 100vh;
width: 100%;
background-image: linear-gradient(rgba(4,9,30,0.8),rgba(4,9,30,0.8)), url(${outside});
position:  relative;
background-size: cover;
`

export const Box = styled.div`
width: 90%;
color: #fff;
position: absolute;
top: 50%;
left: 50%;
transform:translate(-50%,-50%);

`
export const TextBox = styled.div`
    margin-bottom: 60px;
    position: absolute;
    max-width: 30rem;
    top: -5rem;

& h1  {
    font-weight: bold;
    font-size: 3rem;
    color: ${props => props.theme.colors.primary};
    letter-spacing: 1px;
}

& p {
    margin: 10px 0 40px;
    font-size: 20px;
    color: ${props => props.theme.colors.primary};
}

@media (max-width: 768px) {
  
    h1 {
    font-size: 2.2rem; 
    margin-bottom: 16px; 
    letter-spacing: 5px;
    text-align: center;
  }

  p {
    font-size: 1.1rem; 
    line-height: 1.3; 
    margin-bottom: 10px; 
    text-align: center;
  }
}
`


export const About = styled.section`
    display: flex;
    flex-direction: column;
    margin-top: 6rem;

    & > h1 {
        text-align: center;
        font-size: ${props => props.theme.fontSize.headers};
        color: ${props => props.theme.colors.pink};
        padding: 5px 20px;
    }

    @media (max-width: 720px) {
            flex-direction: column;
            
        }
`

export const Container = styled.div`
    padding: 5rem 1rem;
    display: flex;
    width: 100vw;

    @media (max-width: 1024px) {
        padding: 3rem 1rem;
    }
`

export const Photos = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    height: 700px; 
    margin: 0 2rem;

    & > div {
        display: flex;
        position:relative;
        left: 30px;
        width: 100%;
        height: 85%;
        margin: 2.5% 2.5%;
        background-image: url(${livingroom});
        background-size: cover;
        background-position: center;    
    }

    @media (max-width: 1024px) {
        display: none;
    }
`

export const AboutContent= styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 5rem;

    & > p {
        text-align: justify;
        margin-bottom: 1rem;
        color: ${props => props.theme.colors.secondary};
        word-spacing: -2px;
        font-size: 1.1rem;
        font-weight: lighter;
        line-height: 2rem;
        margin-bottom: 3.2rem;
    }
`
export const Button = styled.div`

    & > a {
        text-decoration: none;
        color: ${props => props.theme.colors.primary};
        border: 1px solid  ${props => props.theme.colors.pink};
        padding: 12px 34px;
        font-size: 13px;
        background: ${props => props.theme.colors.pink};
        position: relative;
        cursor: pointer;
        width: fit-content;
        display: inline-block;
        margin: 0 auto;
        
        @media (max-width: 720px) {
            float: center;
            display: block;
            margin-left: auto;
        }
}
    & a:hover{
        color: ${props => props.theme.colors.pink};
        border: 1px solid   ${props => props.theme.colors.pink};
        background: transparent;
        cursor: pointer;    
    }

`

export const Card = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  background: #fff;
  margin-bottom: 50px;
`

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &span{
    font-size: 16px;
    color: ${props => props.theme.colors.primary};
    font-weight: 500;

    &:last-child{
        font-size: 18px;
        color:${props => props.theme.colors.primary};
        font-weight: 700;
    }
  }
`

export const Information = styled.div`

  & h1{
    color: ${props => props.theme.colors.pink};
    text-align: center;
    font-size: ${props => props.theme.fontSize.headers};
    margin-bottom: 10px;
  }

  & p {
    text-align: center;
    font-size: 1.1rem;
    margin-bottom: 50px;
  }

  & div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1.3rem;
  }

  & ul li {
  position: relative; 
  padding-left: 25px; 
  margin-bottom: 10px; 
  }
  & ul {
    list-style-type: none;
    padding: 0; 
    margin: 0; 
  }

 & ul li::before {
    content: ''; 
    position: absolute; 
    left: 0; 
    top: 50%; 
    transform: translateY(-50%); 
    width: 8px; 
    height: 8px; 
    background-color: ${props => props.theme.colors.pink}; 
    border-radius: 50%; 
    margin-right: 14px; 
    }
`