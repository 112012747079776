import styled from 'styled-components'
import { FaEnvelope, FaPhone,} from 'react-icons/fa'
import { IoPerson } from "react-icons/io5"

const icon = `
    font-size: 3rem;
    align-items: center;

    & > path {
        fill: #d55a63
`

export const EmailIcon = styled(FaEnvelope)`
     ${icon}
`

export const PhoneIcon = styled(FaPhone)`
    ${icon}
`

export const PersonIcon = styled(IoPerson)`
    ${icon}
`

export const ContactContainer = styled.div`
  text-align: center;
    

  & h2{
    font-size: ${props => props.theme.fontSize.headers};
    margin-bottom: 2rem;
    color: ${props => props.theme.colors.pink};
  }
 
`

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px; 
  margin: 35px;

  @media (max-width: 768px) {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
  }
`

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & span{
        font-size: 1.2rem;
        margin: 0.5rem 0;
    
    &:last-child {
        font-size: 1rem;
    }
  }

`

export const Location = styled.div`
    height: 550px; 

  & iframe{
        width: 100%; 
        height: 100%;
   }
`