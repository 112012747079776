export const theme = {
  colors: {
    primary: "#e3e3e3",
    secondary: "#454545",
    pink: "#d55a63",
  },
  fontWeight: {
    bold: "bold",
    normal: "normal",
  },
  fontSize: {
    headers: "2.5rem",
  }
  
}
