import React from 'react'
import styled from 'styled-components'

interface PopupProps {
  src: string
  onClose: () => void
}

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledImg = styled.img`
  max-width: 80%;
  max-height: 80%;
`

const Popup: React.FC<PopupProps> = ({ src, onClose }) => {
  return (
    <Backdrop onClick={onClose}>
      <StyledImg src={src} alt="Enlarged pic" />
    </Backdrop>
  )
}

export default Popup
