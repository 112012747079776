import {styled} from "styled-components"

export const Foot = styled.div`
    background-color: ${props => props.theme.colors.pink};
    margin: 0;

    & p{
        color: ${props => props.theme.colors.primary};
        text-align: center;
        padding: 20px;
    }
`