import React from 'react'
import {
  ContactContainer,
  InfoContainer,
  InfoBlock,
  Location,
} from './contactElements'
import { PersonIcon, PhoneIcon, EmailIcon } from './contactElements'

const Contact: React.FC = () => {
  return (
    <>
      <ContactContainer id='contact'>
      <h2>Contact Us</h2>
      <InfoContainer>
        <InfoBlock>
          <PersonIcon />
          <span>Person</span>
          <span>Robert Matracki</span>
        </InfoBlock>
        <InfoBlock>
          <PhoneIcon />
          <span>Phone</span>
          <span>+123 9544 1578</span>
        </InfoBlock>
        <InfoBlock>
          <EmailIcon />
          <span>Email</span>
          <span>matrackir@gmail.com</span>
        </InfoBlock>
      </InfoContainer>
    </ContactContainer>
    <Location>
    <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d299231.4725222646!2d-81.76705511610496!3d26.58301935150801!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88db1bf4c17c572f%3A0xe7db5afaeef61f0d!2s247%20Erie%20Dr%2C%20Naples%2C%20FL%2034110%2C%20Stany%20Zjednoczone!5e0!3m2!1spl!2spl!4v1707148661690!5m2!1spl!2spl" 
    width="600"
    height="450"
    style={{ border: 0 }}
    allowFullScreen={true} 
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
      </Location>
    </>
  )
}

export default Contact
