import React from 'react'
import styled from 'styled-components'

interface GalleryItemProps {
  src: string
  alt?: string
  onClick: () => void
}

const Image = styled.img`
  width: 100%; 
  height: auto;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`

const GalleryItem: React.FC<GalleryItemProps> = ({ src, alt, onClick }) => {
  return <Image src={src} alt={alt} onClick={onClick} />
}

export default GalleryItem
