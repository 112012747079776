import React from "react"
import {
    Foot,
} from './footerElements'

const Footer: React.FC = () => {
    return (
        <>
        <Foot>
            <p>Copyright &copy; 2024 All rights reserved.</p>
        </Foot>
        </>
    )
}

export default Footer